$min-width: 738px;
$screen-xs-max: 768px;
$screen-sm-max: 992px;
$screen-md-max: 1200px;

$content-xs: 750px;
$content-sm: 970px;
$content-md: 1170px;

$font-awesome-font-family: 'Font Awesome 6 Pro';

$touch-screen-breakpoint: 992px;