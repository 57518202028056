.mat-menu-panel.scroll-to-error-menu-panel {
  max-width: 700px;
  max-height: 400px;
}

.mat-mdc-menu-panel.scroll-to-error-menu-panel {
  max-width: 700px;
  max-height: 400px;
}

.mat-mdc-menu-panel, .mat-menu-panel {
  border-radius: 16px!important;
}

.mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
  width: 100%;
}