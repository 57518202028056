@use 'colors';
@use 'variables';

$color-deep-turquoise-hover: darken(colors.$neutral-warm-500, 10%);

html,
body {
  color: colors.$neutral-cool-900;
}

body {
  &.error-page,
  &.login-page {
    border-top: none;
  }
}

:focus {
  outline: none;
}

a {
  color: colors.$neutral-warm-500;
  font-size: 14px;
  text-align: left;

  &:hover {
    color: colors.$neutral-warm-500;
    cursor: pointer;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

ul {
  li {
    list-style: none;
  }
}

.link {
  color: colors.$neutral-warm-500;
  cursor: pointer;

  &:hover {
    color: $color-deep-turquoise-hover;
    text-decoration: underline;
  }

  &.grey {
    color: colors.$neutral-cool-600;

    &:hover {
      color: colors.$neutral-cool-600;
    }
  }
}

// Old styling used for setting background and height of filter containers. Will be removed when all filters has been rewritten
.filter-container {
  background-color: #e8f1f1;
  min-height: 50px;
  width: 100%;
  z-index: 2;
}
