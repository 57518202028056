@use 'colors';
@use 'variables';

// Basic setup of CSS
* {
  box-sizing: border-box;
}

html,
input,
textarea,
button {
  font-family: 'Molnlycke Sans';
}

html {
  overflow-y: scroll;
  font-size: 16px;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;

  color: colors.$neutral-cool-800;
}

body {
  &.error-page,
  &.login-page {
    border-top: none;
  }
}

a {
  text-decoration: none;
}

p {
  margin-top: 0;
}

.page-title {
  color: colors.$green-700;
}