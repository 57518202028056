@use 'variables';
@use 'colors';

.mhc-dialog {
  background-color: colors.$white;
  border-radius: 12px;
  overflow: hidden;
  max-width: min(variables.$content-md - 20px, calc(100vw - rem)) !important;
  max-height: calc(100vh - 1rem) !important; // 1rem is the padding of the dialog. So e.g padding-top: 0.5rem + padding-bottom: 0.5rem = 1rem
  box-shadow: colors.$box-shadow-16px-4percent;

  &--allow-overflow {
    overflow: visible;
  }
}