@use '@angular/material' as mat;

// import base SCSS
@use 'legacy-styles';
@use 'main';
@use 'colors';
@use 'icons';
@use 'fonts';
@use 'typography';
@use 'buttons';
@use 'general-classes';
@use 'copy-tooltip';
@use 'new-layout';

// import global SCSS overrides for components
@use 'shared-module/material-tooltip';
@use 'shared-module/material-menu';
@use 'shared-module/highcharts';
@use 'shared-module/preloader';

@use 'dialogs/dialogs';


// Angular CDK
@import '@angular/cdk/overlay-prebuilt.css';


@include mat.elevation-classes();
@include mat.app-background();
@include mat.all-component-themes(mat.m2-define-light-theme((
  color: (
    primary: mat.m2-define-palette(colors.$md-mhc-primary),
    accent: mat.m2-define-palette(colors.$md-mhc-accent),
    warn: mat.m2-define-palette(colors.$md-mhc-warn)
  ),
  typography: mat.m2-define-typography-config($font-family: 'Molnlycke Sans'),
  density: 0,
)));

// Material overlay fix for showing above old popover.js
.cdk-overlay-container {
  z-index: 2000 !important;
}