@use 'supernova/colors' as supernovaColor;

$white: supernovaColor.$colorBaseWhite;
$black: supernovaColor.$colorBaseBlack;

$green-050: supernovaColor.$colorGreen050; // background-2
$green-100: supernovaColor.$colorGreen100; // background-3
$green-150: supernovaColor.$colorGreen150; // background-4
$green-200: supernovaColor.$colorGreen200; // lighten-1
$green-300: supernovaColor.$colorGreen300; // lighten-2
$green-400: supernovaColor.$colorGreen400; // lighten-3
$green-500: supernovaColor.$colorGreen500; // primary
$green-600: supernovaColor.$colorGreen600; // darken-1
$green-700: supernovaColor.$colorGreen700; // darken-2
$green-800: supernovaColor.$colorGreen800; // darken-3
$green-900: supernovaColor.$colorGreen900;

// Old Secondary (Blue)
$neutral-warm-050: supernovaColor.$colorNeutralWarm050; // background-2
$neutral-warm-100: supernovaColor.$colorNeutralWarm100; // background-3
$neutral-warm-150: supernovaColor.$colorNeutralWarm150; // background-4
$neutral-warm-200: supernovaColor.$colorNeutralWarm200; // lighten-1
$neutral-warm-300: supernovaColor.$colorNeutralWarm300; // lighten-2
$neutral-warm-400: supernovaColor.$colorNeutralWarm400; // lighten-3
$neutral-warm-500: supernovaColor.$colorNeutralWarm500; // primary
$neutral-warm-600: supernovaColor.$colorNeutralWarm600; // darken-1
$neutral-warm-700: supernovaColor.$colorNeutralWarm700; // darken-2
$neutral-warm-800: supernovaColor.$colorNeutralWarm800; // darken-3
$neutral-warm-900: supernovaColor.$colorNeutralWarm900;

$success-050: supernovaColor.$colorSuccess050;
$success-100: supernovaColor.$colorSuccess100;
$success-150: supernovaColor.$colorSuccess150;
$success-200: supernovaColor.$colorSuccess200;
$success-300: supernovaColor.$colorSuccess300;
$success-400: supernovaColor.$colorSuccess400;
$success-500: supernovaColor.$colorSuccess500;
$success-600: supernovaColor.$colorSuccess600;
$success-700: supernovaColor.$colorSuccess700;
$success-800: supernovaColor.$colorSuccess800;
$success-900: supernovaColor.$colorSuccess900;

$critical-050: supernovaColor.$colorCritical050; // background-2
$critical-100: supernovaColor.$colorCritical100; // background-3
$critical-150: supernovaColor.$colorCritical150; // background-4
$critical-200: supernovaColor.$colorCritical200; // lighten-1
$critical-300: supernovaColor.$colorCritical300; // lighten-2
$critical-400: supernovaColor.$colorCritical400; // lighten-3
$critical-500: supernovaColor.$colorCritical500; // primary
$critical-600: supernovaColor.$colorCritical600; // darken-1
$critical-700: supernovaColor.$colorCritical700; // darken-2
$critical-800: supernovaColor.$colorCritical800; // darken-3
$critical-900: supernovaColor.$colorCritical900;

$warning-050: supernovaColor.$colorWarning050; // background-2
$warning-100: supernovaColor.$colorWarning100; // background-3
$warning-150: supernovaColor.$colorWarning150; // background-4
$warning-200: supernovaColor.$colorWarning200; // lighten-1
$warning-300: supernovaColor.$colorWarning300; // lighten-2
$warning-400: supernovaColor.$colorWarning400; // lighten-3
$warning-500: supernovaColor.$colorWarning500; // primary
$warning-600: supernovaColor.$colorWarning600; // darken-1
$warning-700: supernovaColor.$colorWarning700; // darken-2
$warning-800: supernovaColor.$colorWarning800; // darken-3
$warning-900: supernovaColor.$colorWarning900;

// Old Accent (Old Purple, Now blue)
$information-050: supernovaColor.$colorInformation050; // background-2
$information-100: supernovaColor.$colorInformation100; // background-3
$information-150: supernovaColor.$colorInformation150; // background-4
$information-200: supernovaColor.$colorInformation200; // lighten-1
$information-300: supernovaColor.$colorInformation300; // lighten-2
$information-400: supernovaColor.$colorInformation400; // lighten-3
$information-500: supernovaColor.$colorInformation500; // primary
$information-600: supernovaColor.$colorInformation600; // darken-1
$information-700: supernovaColor.$colorInformation700; // darken-2
$information-800: supernovaColor.$colorInformation800; // darken-3
$information-900: supernovaColor.$colorInformation900;

// Grey
$neutral-cool-050: supernovaColor.$colorNeutralCool050; // background-2
$neutral-cool-100: supernovaColor.$colorNeutralCool100; // background-3
$neutral-cool-150: supernovaColor.$colorNeutralCool150; // background-4
$neutral-cool-200: supernovaColor.$colorNeutralCool200; // lighten-1
$neutral-cool-300: supernovaColor.$colorNeutralCool300; // lighten-2
$neutral-cool-400: supernovaColor.$colorNeutralCool400; // lighten-3
$neutral-cool-500: supernovaColor.$colorNeutralCool500; // lighten-4
$neutral-cool-600: supernovaColor.$colorNeutralCool600; // lighten-5
$neutral-cool-700: supernovaColor.$colorNeutralCool700; // lighten-6
$neutral-cool-800: supernovaColor.$colorNeutralCool800; // primary
$neutral-cool-900: supernovaColor.$colorNeutralCool900; // darken-1

$accent-green: supernovaColor.$colorAccentGreen;
$accent-coral: supernovaColor.$colorAccentCoral;
$accent-purple: supernovaColor.$colorAccentPurple;
$accent-yellow: supernovaColor.$colorAccentYellow;

// Box shadows
$box-shadow-16px-4percent: 0px 0px 16px 0px rgba(0, 0, 0, 0.04);
$box-shadow-16px-16percent: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
$box-shadow-8px-3percent: 0px 0px 8px 0px rgba(0, 0, 0, 0.03);

// Disabled
$disabled-dark: #7A7A7A;

// Material theme
$md-mhc-primary: (
  50: $green-050,
  100: $green-050,
  200: $green-200,
  300: $green-300,
  400: $green-400,
  500: $green-500,
  600: $green-500,
  700: $green-600,
  800: $green-700,
  900: $green-800,
  A100: $green-050,
  A200: $green-200,
  A400: $green-400,
  A700: $green-500,
  contrast: (
    50: $neutral-cool-800,
    100: $neutral-cool-800,
    200: $neutral-cool-800,
    300: $neutral-cool-800,
    400: $neutral-cool-800,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $neutral-cool-800,
    A200: $neutral-cool-800,
    A400: $neutral-cool-800,
    A700: $neutral-cool-800
  )
);

$md-mhc-accent: (
  50: $neutral-warm-050,
  100: $neutral-warm-050,
  200: $neutral-warm-200,
  300: $neutral-warm-300,
  400: $neutral-warm-400,
  500: $neutral-warm-500,
  600: $neutral-warm-500,
  700: $neutral-warm-600,
  800: $neutral-warm-700,
  900: $neutral-warm-800,
  A100: $neutral-warm-050,
  A200: $neutral-warm-200,
  A400: $neutral-warm-400,
  A700: $neutral-warm-500,
  contrast: (
    50: $neutral-cool-800,
    100: $neutral-cool-800,
    200: $neutral-cool-800,
    300: $neutral-cool-800,
    400: $neutral-cool-800,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $neutral-cool-800,
    A200: $neutral-cool-800,
    A400: $neutral-cool-800,
    A700: $neutral-cool-800
  )
);

$md-mhc-warn: (
  50: $critical-050,
  100: $critical-050,
  200: $critical-200,
  300: $critical-300,
  400: $critical-400,
  500: $critical-500,
  600: $critical-500,
  700: $critical-600,
  800: $critical-700,
  900: $critical-800,
  A100: $critical-050,
  A200: $critical-200,
  A400: $critical-400,
  A700: $critical-500,
  contrast: (
    50: $neutral-cool-800,
    100: $neutral-cool-800,
    200: $neutral-cool-800,
    300: $neutral-cool-800,
    400: $neutral-cool-800,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $neutral-cool-800,
    A200: $neutral-cool-800,
    A400: $neutral-cool-800,
    A700: $neutral-cool-800
  )
);