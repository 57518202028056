// Variables
$font-weights: (
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700
);

$font-sizes: (
  5xs: 0.5rem, // 8px
  4xs: 0.625rem, // 10px
  3xs: 0.75rem, // 12px 
  2xs: 0.875rem, // 14px 
  xs: 1rem, // 16px
  sm: 1.25rem, // 20px
  md: 1.5rem, // 24px
  lg: 1.75rem, // 28px
  xl: 2rem, // 32px
  2xl: 2.25rem, // 36px
  3xl: 3rem, // 48px
  4xl: 4rem // 64px
);

// Base typography styles
@mixin typography-base {
  margin: 0;
}

@mixin combinations($line-height, $letter-spacing) {

  // Generate combinations of size and weight
  @each $size-name, $size-value in $font-sizes {
    @each $weight-name, $weight-value in $font-weights {
      &-#{$size-name}-#{$weight-name} {
        @include typography-base;
        font-size: $size-value;
        font-weight: $weight-value;
        line-height: $line-height;
        letter-spacing: $letter-spacing;
      }
    }
  }
}

.display {
  @include combinations(1.25em, 0.02em);
}

.heading {
  @include combinations(1.35em, 0.01em);
}

.body {
  @include combinations(1.5em, 0.01em);
}

.label {
  @include combinations(1.3em, 0.01em);
}

// Microcopy styles
@mixin microcopy {
  @include typography-base;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: 0.01em;
}

.microcopy {
  @include microcopy;
}

.microcopy-bold {
  @include microcopy;
  font-weight: 700;
}

// Overline style
@mixin overline {
  @include typography-base;
  font-size: 0.875rem;
  line-height: 200%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
}

.overline {
  @include overline;
}

// Button style
@mixin button {
  @include typography-base;
  font-size: 0.875rem;
  line-height: 160%;
  letter-spacing: 0.04em;
}

.button {
  @include button;
}

.button-bold {
  @include button;
  font-weight: 700;
}
