@use 'colors';

button {
  font-size: 1rem;
  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: mhcButtonRipple 600ms linear;
    z-index: 1;
  }

  @keyframes mhcButtonRipple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
}
