@font-face {
  font-family: 'Molnlycke Sans';
  src: url('/assets/fonts/MolnlyckeSans-Light.woff2') format('woff2'), url('/assets/fonts/MolnlyckeSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Molnlycke Sans';
  src: url('/assets/fonts/MolnlyckeSans-Bold.woff2') format('woff2'), url('/assets/fonts/MolnlyckeSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Molnlycke Sans';
  src: url('/assets/fonts/MolnlyckeSans-LightItalic.woff2') format('woff2'), url('/assets/fonts/MolnlyckeSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Molnlycke Sans';
  src: url('/assets/fonts/MolnlyckeSans-Italic.woff2') format('woff2'), url('/assets/fonts/MolnlyckeSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Molnlycke Sans';
  src: url('/assets/fonts/MolnlyckeSans-Regular.woff2') format('woff2'), url('/assets/fonts/MolnlyckeSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Molnlycke Sans';
  src: url('/assets/fonts/MolnlyckeSans-Medium.woff2') format('woff2'), url('/assets/fonts/MolnlyckeSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
