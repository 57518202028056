@use 'colors';

// Alignment

.pull-right {
  float: right;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.align-items-center {
  align-items: center;
}