@use 'colors';

.copyable {
    cursor: pointer;
    position: relative;

    &:active {
        background-color: colors.$neutral-cool-100;
    }
}

.mhc-copy-tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%) translateY(-8px);
    background: colors.$neutral-cool-800;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    animation: mhcFadeIn 0.2s ease-in;
    z-index: 1000;
    pointer-events: none;
}

@keyframes mhcFadeIn {
    from {
        opacity: 0;
        transform: translateX(-50%) translateY(-80%);
    }

    to {
        opacity: 1;
        transform: translateX(-50%) translateY(-100%) translateY(-8px);
    }
}