$colorGreen900: #00291d;    
$colorGreen800: #003626;    
$colorGreen700: #00402f;    
$colorGreen600: #025f3a;    
$colorGreen500: #067a3c;    
$colorGreen400: #57a67b;    
$colorGreen300: #8cc3a5;    
$colorGreen200: #bfdfcd;    
$colorGreen100: #edf7f1;    
$colorGreen050: #f8fcf9;    
$colorNeutralWarm900: #1a1a19;    
$colorNeutralWarm800: #292a28;    
$colorNeutralWarm700: #3c3c39;    
$colorNeutralWarm600: #5c5d56;    
$colorNeutralWarm500: #727269;    
$colorNeutralWarm400: #8f8e85;    
$colorNeutralWarm300: #b6b4aa;    
$colorNeutralWarm200: #dad7d0;    
$colorNeutralWarm100: #f5f2ee;    
$colorNeutralWarm050: #fcfaf8;    
$colorNeutralCool900: #232523;    
$colorNeutralCool800: #333737;    
$colorNeutralCool700: #42484a;    
$colorNeutralCool600: #515a5e;    
$colorNeutralCool500: #606b71;    
$colorNeutralCool400: #6e7b83;    
$colorNeutralCool300: #9aa3a8;    
$colorNeutralCool200: #c5cacc;    
$colorNeutralCool100: #f0f2f0;    
$colorNeutralCool050: #fafbfa;    
$colorSuccess900: #001b03;    
$colorSuccess800: #003607;    
$colorSuccess700: #00500a;    
$colorSuccess600: #006d0e;    
$colorSuccess500: #038313;    
$colorSuccess400: #14af28;    
$colorSuccess300: #99cfa0;    
$colorSuccess200: #d5ecd8;    
$colorSuccess100: #f0f9f2;    
$colorSuccess050: #f8fcf9;    
$colorInformation900: #00111a;    
$colorInformation800: #002233;    
$colorInformation700: #00334d;    
$colorInformation600: #004466;    
$colorInformation500: #0077b2;    
$colorInformation400: #189bdc;    
$colorInformation300: #75c7f0;    
$colorInformation200: #c8e9f9;    
$colorInformation100: #eef8fd;    
$colorInformation050: #f5fcff;    
$colorCritical900: #260e0d;    
$colorCritical800: #521814;    
$colorCritical700: #821e17;    
$colorCritical600: #b81f14;    
$colorCritical500: #d33227;    
$colorCritical400: #ff7066;    
$colorCritical300: #ffa099;    
$colorCritical200: #ffbcb8;    
$colorCritical100: #ffe2e0;    
$colorCritical050: #fff5f5;    
$colorWarning900: #28200c;    
$colorWarning800: #664a04;    
$colorWarning700: #997006;    
$colorWarning600: #cc9508;    
$colorWarning500: #ffba0a;    
$colorWarning400: #ffc83b;    
$colorWarning300: #ffe39d;    
$colorWarning200: #fff1ce;    
$colorWarning100: #fffaeb;    
$colorWarning050: #fffcf5;    
$colorAccentGreen: #97ff86;    
$colorAccentCoral: #ff7066;    
$colorAccentPurple: #c8c0f9;    
$colorAccentYellow: #f2ffa0;    
$colorNeutralWarm150: #ebeae6;    
$colorNeutralCool150: #dadddd;    
$colorBaseBlack: #0b1215;    
$colorBaseWhite: #ffffff;    
$colorGreen150: #d6ebdf;    
$colorSuccess150: #e3f3e5;    
$colorInformation150: #dbf0fb;    
$colorCritical150: #ffcfcc;    
$colorWarning150: #ffffff;    
$colorBrandColorsMNlyckeBrand: $colorSuccess400;    
$colorBrandColorsGreen1: $colorGreen500;    
$colorBrandColorsGreen2: $colorGreen700;    
$colorBrandColorsGreen3: $colorGreen900;    
$colorBrandColorsNeutralWarm1: $colorNeutralWarm100;    
$colorBrandColorsNeutralWarm2: $colorNeutralWarm200;    
$colorBrandColorsNeutralWarm3: $colorNeutralWarm400;    
$colorBrandColorsNeutralCool1: $colorNeutralCool100;    
$colorBrandColorsNeutralCool2: $colorNeutralCool400;    
$colorBrandColorsNeutralCool3: $colorNeutralCool900;    
$colorBrandColorsAccentGreen: $colorAccentGreen;    
$colorBrandColorsAccentCoral: $colorAccentCoral;    
$colorBrandColorsAccentPurple: $colorAccentPurple;    
$colorBrandColorsAccentYellow: $colorAccentYellow;    
$colorTextDark: $colorNeutralCool900;    
$colorTextMedium: $colorNeutralCool600;    
$colorTextLight: $colorBaseWhite;    
$colorTextBrand: $colorBrandColorsGreen1;    
$colorButtonButtonPrimary: $colorGreen500;    
$colorButtonButtonPrimaryHover: $colorGreen600;    
$colorButtonButtonWhite: $colorBaseWhite;    
$colorButtonDisabled: $colorNeutralWarm150;    
$colorButtonCritical: $colorCritical500;    
$colorButtonCriticalHover: $colorCritical700;    
$colorButtonGrey: $colorNeutralWarm600;    
$colorButtonGreyHover: $colorNeutralWarm600;    
$colorButtonWhiteHover: $colorNeutralWarm150;    
$colorButtonDisabledText: $colorNeutralWarm500;    
