@use 'colors';

.mhc-preloader {
  position: fixed;
  pointer-events: none;
  user-select: none;
  transition: opacity 0.15s linear;
  opacity: 0;
  display: flex;
  z-index: 2 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  gap: 32px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: colors.$white;
}

.mhc-preloader img {
  height: 100px;
}

mhc-root:empty + .mhc-preloader {
  opacity: 1;
  z-index: 3 !important;
}

.mhc-preloader .spinner {
  animation: preloaderRotate 2s linear infinite;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
}

.mhc-preloader .spinner .path {
  stroke: colors.$green-500;
  stroke-linecap: round;
  animation: preloaderDash 1.5s ease-in-out infinite;
}

@keyframes preloaderRotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes preloaderDash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
