// layout.scss
@use 'colors';
@use 'variables';

// Layout Page
.page {
  //padding: 24px 0 40px 0;
  width: 100%;
  min-height: 65vh;
  
  // Color variants
  &--blue {
    background-color: colors.$neutral-warm-100;
  }
  
  &--green {
    background-color: colors.$green-050;
  }
  
  &--neutral-cool {
    background-color: colors.$neutral-cool-050;
  }
  
  &--transparent {
    background-color: transparent;
  }
  
  // Padding modifier
  &--no-padding {
    padding: 0;
  }

  &--padding {
    padding: 24px 0 40px 0;
  }
}

// Layout Content
.page-content {
  border-radius: 12px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  
  // Base styles for mobile-first approach
  //padding: 16px;
  max-width: 100%;
  
  // Color variants
  &--white {
    background-color: colors.$white;
  }
  
  &--transparent {
    background-color: transparent;
  }
  
  // Modifiers
  &--shadow {
    box-shadow: colors.$box-shadow-16px-4percent;
  }
  
  &--with-margin {
    margin-bottom: 16px;
  }
  
  &--with-padding {
    padding: 24px;
  }
  
  &--with-padding-x {
    padding-right: 16px;
    padding-left: 16px;
  }

  &--padding-title{
    padding: 24px 0 16px;
  }

  &--padding-tabs{
    padding: 16px 0;
  }

  &--padding-bottom{
    padding: 0 0 40px;
  }
  
  // Responsive breakpoints
  @media (min-width: variables.$screen-xs-max) {
    width: variables.$content-xs;
  }
  
  @media (min-width: variables.$screen-sm-max) {
    width: variables.$content-sm;
    
    &--with-padding-x {
      padding-right: 24px;
      padding-left: 24px;
    }
  }
  
  @media (min-width: variables.$screen-md-max) {
    width: variables.$content-md;
    
    &--with-padding {
      padding: 24px;
    }
    
    &--with-padding-x {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}